@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#button{
  background: #51afc4;
}
#whats{
  color: #51afc4;
}
#curv1{
  border: #51afc4;
}
#curv2{
  border: #51afc4;
}
.sighn{
color: #51afc4;
font-size: 70px;
font: bold;
}

        .card-transition {
            transition: transform 0.3s, box-shadow 0.3s;
        }
        .card-transition:hover {
            transform: scale(1.05);
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        }
.line{
  border-color: #51afc4;
}
.btn{
  background-color: #51afc4;
}
.btn :hover{
  background-color: #67a2af;
}

.heading{
  color: #51afc4;
}
